<template>
  <div class="media-planning-campaign-dialog">
    <portal to="base-modal">
      <base-modal @closed_requested="close_dialog">
        <form @submit.prevent="save" ref="form">
          <h3>
            <template v-if="campaign.id">Kampagne aktualisieren</template>
            <template v-else>Kampagne hinzufügen</template>
            <a @click.stop.prevent="copy_campaign_id" title="Kampagnen-ID kopieren">
              <i class="fi-clipboard"></i>
            </a>
          </h3>

          <div v-if="errors" class="callout alert">
            <h5>Error</h5>
            <p v-for="error in errors">{{ error }}</p>
            <button @click.stop="errors = null" class="close-button" aria-label="Dismiss alert" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <fieldset>
            <template v-for="integration in integrations">
              <input type="radio"
                     name="new_campaign_channel"
                     :value="integration.channel_id"
                     :id="`new_campaign_${integration.channel_id}`"
                     v-model="campaign.channel_id"
                     :disabled="campaign.id || is_loading">
              <label :for="`new_campaign_${integration.channel_id}`"
                     class="media-planning-campaign-dialog--radio-label">
                <channel-icon :channel_title="integration.channel_title"></channel-icon>
                {{ integration.channel_title }}
              </label>
            </template>
            <label>
              Titel:
              <input type="text" v-model="campaign.title" :disabled="is_loading">
            </label>
          </fieldset>

          <div class="media-planning-campaign-dialog--grid3 media-planning-campaign-dialog--callout">
            <template v-for="(flight, index) in campaign.flights">
              <div class="media-planning-campaign-dialog--cell">
                <vue-ctk-date-time-picker id="media_plan_start_date"
                                          format="YYYY-MM-DD"
                                          hint="Startdatum *"
                                          color="#36bbb1"
                                          button-color="#36bbb1"
                                          button-now-translation="Jetzt!"
                                          :only-date="true"
                                          :no-button-now="true"
                                          label="Erster Tag"
                                          formatted="ll"
                                          :disabledDates="disabled_dates(flight)"
                                          :error="!flight.start_date && flight.active_reporting"
                                          :disabled="is_loading"
                                          v-model="flight.start_date"/>
              </div>
              <div class="media-planning-campaign-dialog--cell">
                <vue-ctk-date-time-picker id="media_plan_end_date"
                                          format="YYYY-MM-DD"
                                          hint="Enddatum *"
                                          color="#36bbb1"
                                          button-color="#36bbb1"
                                          button-now-translation="Jetzt!"
                                          :only-date="true"
                                          :no-button-now="true"
                                          label="Letzter Tag"
                                          formatted="ll"
                                          :disabledDates="disabled_dates(flight)"
                                          :min-date="flight.start_date"
                                          :error="(flight.start_date && flight.end_date && flight.start_date > flight.end_date) || (!flight.end_date && flight.active_reporting)"
                                          :disabled="is_loading"
                                          v-model="flight.end_date"/>
              </div>
              <div class="media-planning-campaign-dialog--cell">
                <toggle-button :state="flight.active_reporting"
                               :disabled="is_loading"
                               style="margin-top: 0.5em"
                               text="Reporting aktivieren"
                               @toggled="toggle_active_reporting(index)">
                </toggle-button>
                <div class="text-right float-right">
                  <button @click.prevent="remove_flight(index)"
                          class="media-planning-campaign-dialog--button--alert"
                          style="margin-bottom: 0; margin-top: 0.5em"
                          :disabled="is_loading"
                          :class="disabled_button_class">
                    <i class="fi-x"></i>
                  </button>
                </div>
              </div>
            </template>

            <div class="media-planning-campaign-dialog--cell">
              <button v-if="flights_configured"
                      @click.prevent="add_flight"
                      class="media-planning-campaign-dialog--button"
                      style="text-align: right;"
                      :disabled="is_loading"
                      :class="disabled_button_class">
                <i class="fi-plus"></i>
                Flight hinzufügen
              </button>
            </div>
          </div>

          <div class="media-planning-campaign-dialog--callout">
            <fieldset>
              <label>Ziel:</label>

              <div style="margin-left: 0">
                <input type="radio"
                       name="campaign_target_type"
                       :value="null"
                       :id="`campaign_target_type_null`"
                       :disabled="is_loading"
                       v-model="campaign.target_type">
                <label :for="`campaign_target_type_null`">
                  Ohne
                </label>

                <input type="radio"
                       name="campaign_target_type"
                       value="impressions"
                       :id="`campaign_target_type_impressions`"
                       :disabled="is_loading"
                       v-model="campaign.target_type">
                <label :for="`campaign_target_type_impressions`">
                  Impressions
                </label>

                <input type="radio"
                       name="campaign_target_type"
                       value="clicks"
                       :id="`campaign_target_type_clicks`"
                       :disabled="is_loading"
                       v-model="campaign.target_type">
                <label :for="`campaign_target_type_clicks`">
                  Clicks
                </label>
              </div>

              <label style="margin-left: 0">
                <span v-if="campaign.target_type === 'impressions'">Summe Impressions</span>
                <span v-else-if="campaign.target_type === 'clicks'">Summe Clicks</span>
                <span v-else>Summe</span>
                <vue-numeric v-model="campaign.target_value"
                             :min="1"
                             separator="."
                             :disabled="!campaign.target_value || is_loading">
                </vue-numeric>
              </label>
            </fieldset>
          </div>

          <div class="media-planning-campaign-dialog--callout media-planning-campaign-dialog--grid2">
            <fieldset class="media-planning-campaign-dialog--cell" style="margin-left: 0">
              <label>Umsatz-Typ:</label>

              <input type="radio"
                     name="campaign_turnover_type"
                     :value="null"
                     :id="`campaign_turnover_type_null`"
                     v-model="campaign.turnover_type"
                     disabled="disabled">
              <label :for="`campaign_turnover_type_null`">
                Ohne
              </label>

              <input type="radio"
                     name="campaign_turnover_type"
                     value="tkp"
                     :id="`campaign_turnover_type_tkp`"
                     v-model="campaign.turnover_type"
                     :disabled="campaign.target_type !== 'impressions' || is_loading">
              <label :for="`campaign_turnover_type_tkp`">
                <abbr title="Tausend-Kontakt-Preis">TKP</abbr>
              </label>

              <input type="radio"
                     name="campaign_turnover_type"
                     value="cpc"
                     :id="`campaign_turnover_type_cpc`"
                     v-model="campaign.turnover_type"
                     :disabled="campaign.target_type !== 'clicks' || is_loading">
              <label :for="`campaign_turnover_type_cpc`">
                <abbr title="Cost-per-Click">CPC</abbr>
              </label>

              <input type="radio"
                     name="campaign_turnover_type"
                     value="hf"
                     id="campaign_turnover_type_hf"
                     v-model="campaign.turnover_type"
                     :disabled="campaign.dummy || is_loading">
              <label :for="`campaign_turnover_type_hf`"><abbr title="Handling fee">HF</abbr></label>

              <input type="radio"
                     name="campaign_turnover_type"
                     value="natural_discount"
                     id="campaign_turnover_type_natural_discount"
                     :disabled="is_loading"
                     v-model="campaign.turnover_type">
              <label :for="`campaign_turnover_type_natural_discount`">
                <abbr title="Naturalrabatt">NR</abbr>
              </label>

              <div class="media-planning-campaign-dialog--grid2">
                <label v-if="campaign.turnover_type" class="media-planning-campaign-dialog--cell"
                       style="margin-left: 0">
                  {{ turnover_type_label }}
                  <vue-numeric v-model="campaign.turnover_value"
                               :min="min_turnover_value"
                               separator="."
                               decimal-separator=","
                               :precision=2
                               :currency="campaign.turnover_type === 'hf' ? '%' : 'Euro'"
                               currency-symbol-position="suffix"
                               :disabled="['natural_discount'].includes(campaign.turnover_type) || is_loading">
                  </vue-numeric>
                </label>

                <label v-if="campaign.turnover_type && !campaign.dummy"
                       class="media-planning-campaign-dialog--cell" style="margin-left: 0">
                  Budget
                  <vue-numeric v-model="campaign.turnover_budget"
                               :min="null"
                               separator="."
                               decimal-separator=","
                               :precision=2
                               currency="Euro"
                               currency-symbol-position="suffix"
                               :disabled="[null, 'natural_discount'].includes(campaign.turnover_type) || is_loading">
                  </vue-numeric>
                </label>
              </div>
            </fieldset>

            <fieldset v-if="campaign.dummy" class="media-planning-campaign-dialog--cell">
              <label>Kosten-Typ <small>(Dummy-Kampagnen)</small>:</label>

              <input type="radio"
                     name="campaign_cost_type"
                     :value="null"
                     :id="`campaign_cost_type_null`"
                     :disabled="is_loading"
                     v-model="campaign.cost_type">
              <label :for="`campaign_cost_type_null`">
                Ohne
              </label>

              <input type="radio"
                     name="campaign_cost_type"
                     value="tkp"
                     :id="`campaign_cost_type_tkp`"
                     v-model="campaign.cost_type"
                     :disabled="campaign.target_type !== 'impressions' || is_loading">
              <label :for="`campaign_cost_type_tkp`">
                <abbr title="Tausend-Kontakt-Preis">TKP</abbr>
              </label>

              <input type="radio"
                     name="campaign_cost_type"
                     value="cpc"
                     :id="`campaign_cost_type_cpc`"
                     v-model="campaign.cost_type"
                     :disabled="campaign.target_type !== 'clicks' || is_loading">
              <label :for="`campaign_cost_type_cpc`">
                <abbr title="Cost-per-Click">CPC</abbr>
              </label>

              <label v-if="campaign.cost_type">
                Preis
                <vue-numeric v-model="campaign.cost_value"
                             :min="0.01"
                             separator="."
                             :precision=2
                             currency="Euro"
                             currency-symbol-position="suffix"
                             :disabled="is_loading">
                </vue-numeric>
              </label>
            </fieldset>
          </div>

          <div class="media-planning-campaign-dialog--callout">
            <fieldset>
              <label>Dummy-Kampagne:</label>

              <input type="checkbox"
                     name="campaign_dummy"
                     :value="null"
                     :id="`campaign_dummy`"
                     v-model="campaign.dummy"
                     :disabled="!!campaign.integrations.length || is_loading">
              <label :for="`campaign_dummy`">
                Kampagne ohne Integrationen
              </label>
            </fieldset>
          </div>

          <div class="media-planning-campaign-dialog--callout">
            <fieldset>
              <label for="xv_order_id">crossvertise Order-ID</label>
              <div class="input-group">
                <input type="text"
                       v-model="campaign.xv_order_id"
                       id="xv_order_id"
                       class="input-group-field"
                       :disabled="is_loading">
                <div class="input-group-button">
                  <button @click.prevent="delete_xv_order_id" type="submit"
                          class="button media-planning-campaign-dialog--button--alert" :disabled="is_loading">
                    <i class="fi-trash"></i>
                  </button>
                </div>
              </div>
            </fieldset>
          </div>

          <div>
            <button v-if="campaign.id"
                    @click.stop.prevent="delete_campaign"
                    :class="disabled_button_class"
                    :disabled="is_loading"
                    class="media-planning-campaign-dialog--button--alert">
              <i class="fi-alert"></i>
              Löschen ...
            </button>
            <button @click="close_dialog"
                    :disabled="is_loading"
                    class="media-planning-campaign-dialog--button--abort">
              <i class="fi-x"></i>
              Abbrechen
            </button>
            <button v-if="campaign_configured"
                    :disabled="is_loading"
                    class="media-planning-campaign-dialog--button"
                    :class="disabled_button_class">
              <i class="fi-save"></i>
              Kampagne
            </button>
          </div>
        </form>
      </base-modal>
    </portal>
  </div>
</template>

<script>
import "./media-planning-campaign-dialog.scss"
import BaseModal from "../base-modal/base-modal-app"
import VueCtkDateTimePicker from '../../lib/vue-ctk-date-time-picker.common'
import '../../lib/vue-ctk-date-time-picker.css'
import BooleanIcon from '../boolean-icon/boolean-icon'
import ToggleButton from "../toggle-button/toggle-button"
import ChannelIcon from "../channel-icon/channel-icon-app"
import axios from "axios"
import moment from "moment"
import VueNumeric from 'vue-numeric'
import Vue from 'vue/dist/vue.esm'

Vue.component('vue-ctk-date-time-picker', VueCtkDateTimePicker)

export default {
  name:       "media-planning-campaign-dialog",
  props:      {
    media_planning: {
      required: true,
    },
    campaign:       {
      required: false,
      default() {
        return {
          id:              null,
          channel_id:      null,
          title:           "",
          flights:         [{
            start_date:       null,
            end_date:         null,
            active_reporting: false,
          }],
          target_type:     null,
          target_value:    null,
          turnover_type:   null,
          turnover_value:  10.0,
          turnover_budget: null,
          cost_type:       null,
          cost_value:      null,
          dummy:           false,
          integrations:    [],
          xv_order_id:     null,
        }
      },
    },
    integrations:   {
      required: true,
    },
  },
  components: {
    BaseModal,
    VueCtkDateTimePicker,
    BooleanIcon,
    ToggleButton,
    ChannelIcon,
    VueNumeric,
  },
  data() {
    return {
      xv_order_id: this.campaign.xv_order_id,
      is_loading:  false,
      errors:      null,
    }
  },
  methods:  {
    toggle_active_reporting(index) {
      const flight = this.campaign.flights[index];
      flight.active_reporting = !flight.active_reporting
    },
    add_flight() {
      this.campaign.flights.push({ start_date: null, end_date: null, active_reporting: false })
    },
    save() {
      if (this.is_loading) return
      if (!this.campaign_configured) return

      this.is_loading = true
      this.errors = null

      if (this.campaign.id) {
        axios({
          method:  'post',
          url:     `/media_plannings/${this.media_planning.id}/update_campaign`,
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
          data:    {
            campaign_id:     this.campaign.id,
            channel_id:      this.campaign.channel_id,
            title:           this.campaign.title,
            flights:         this.campaign.flights,
            target_type:     this.campaign.target_type,
            target_value:    this.campaign.target_value,
            turnover_type:   this.campaign.turnover_type,
            turnover_value:  this.campaign.turnover_value,
            turnover_budget: this.campaign.turnover_budget || null,
            cost_type:       this.campaign.cost_type,
            cost_value:      this.campaign.cost_value,
            dummy:           this.campaign.dummy,
            xv_order_id:     this.campaign.xv_order_id || null,
          },
        })
            .then(_response => {
              this.is_loading = false
              this.close_dialog()
            })
            .catch(error => {
              this.is_loading = false
              this.errors = error.response.data.errors.map(error => error.title)
              console.log("ERROR", error.response.data)
            })
      } else {
        axios({
          method:  'post',
          url:     `/media_plannings/${this.media_planning.id}/add_campaign`,
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
          data:    {
            channel_id:      this.campaign.channel_id,
            title:           this.campaign.title,
            flights:         this.campaign.flights,
            target_type:     this.campaign.target_type,
            target_value:    this.campaign.target_value,
            turnover_type:   this.campaign.turnover_type,
            turnover_value:  this.campaign.turnover_value,
            turnover_budget: this.campaign.turnover_budget || null,
            cost_type:       this.campaign.cost_type,
            cost_value:      this.campaign.cost_value,
            dummy:           this.campaign.dummy,
            xv_order_id:     this.campaign.xv_order_id || null,
          },
        })
            .then(_response => {
              this.is_loading = false
              this.close_dialog()
            })
            .catch(error => {
              this.is_loading = false
              this.errors = error.response.data.errors.map(error => error.title)
              console.log("ERROR", error.response.data)
            })
      }
    },
    delete_campaign() {
      let confirmation = confirm("Willst du die Kampagne wirklich löschen?")
      if (!confirmation) return

      this.is_loading = true
      this.errors = null

      axios({
        method:  'delete',
        url:     `/media_plannings/${this.media_planning.id}/delete_campaign`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        data:    {
          campaign_id: this.campaign.id,
        },
      })
          .then((_response) => {
            this.is_loading = false
            this.close_dialog()
          })
          .catch((error) => {
            this.is_loading = false
            this.errors = error.response.data.errors.map(error => error.title)
            console.log("ERROR", error.response.data)
          })
    },
    remove_flight(index) {
      this.campaign.flights.splice(index, 1);
    },
    disabled_dates(current_flight) {
      return this.campaign.flights.flatMap(flight => {
        if (flight === current_flight) return []
        const flight_dates = []
        let current_date = flight.start_date
        while (current_date <= flight.end_date) {
          flight_dates.push(current_date)
          current_date = moment(current_date).add(1, "day").format("YYYY-MM-DD")
        }
        return flight_dates
      })
    },
    delete_xv_order_id() {
      this.campaign.xv_order_id = null
    },
    copy_campaign_id() {
      navigator.clipboard.writeText(this.campaign.id)
    },
    close_dialog() {
      this.$emit("closed_requested")
    },
  },
  computed: {
    campaign_configured() {
      if (!this.campaign.channel_id) return
      if (!this.campaign.title) return
      if (!this.campaign.flights.length) return
      if (!this.flights_configured) return
      if (this.campaign.target_type && !this.campaign.target_value) return
      if (this.campaign.turnover_type !== "natural_discount") {
        if (!this.campaign.turnover_type || !this.campaign.turnover_value) return
      }
      if (this.campaign.turnover_type === "hf" && !this.campaign.turnover_budget) return
      if (this.campaign.dummy && !this.campaign.target_type) return
      return true
    },
    flights_configured() {
      return this.campaign.flights.every(flight => flight.start_date && flight.end_date)
    },
    disabled_button_class() {
      if (this.is_loading) return "media-planning-campaign-dialog--button--disabled"
      return "media-planning-campaign-dialog--button"
    },
    min_turnover_value() {
      if (this.campaign.turnover_type === "natural_discount") return 0.0
      return 0.01
    },
    turnover_type_label() {
      if (this.campaign.turnover_type === "tkp") return "TKP"
      if (this.campaign.turnover_type === "cpc") return "CPC"
      if (this.campaign.turnover_type === "hf") return "Handling fee"
      if (this.campaign.turnover_type === "natural_discount") return "Naturalrabatt"
    },
  },
  watch:    {
    "campaign.target_type"() {
      if (this.campaign.target_type === null) {
        this.campaign.target_value = null
      } else {
        this.campaign.target_value = 10.0
      }
      if (this.campaign.target_type === "impressions") {
        this.campaign.turnover_type = "tkp"
        if (this.campaign.cost_type) {
          this.campaign.cost_type = "tkp"
        }
      } else if (this.campaign.target_type === "clicks") {
        this.campaign.turnover_type = "cpc"
        if (this.campaign.cost_type) {
          this.campaign.cost_type = "cpc"
        }
      }
    },
    "campaign.turnover_type"() {
      if (this.campaign.turnover_type === "natural_discount") return this.campaign.turnover_value = 0.0
      this.campaign.turnover_value = 10.0
    },
    "campaign.cost_type"() {
      if (this.campaign.cost_type === null) {
        this.campaign.cost_value = null
      } else {
        this.campaign.cost_value = 5.0
      }
    },
    "campaign.dummy"() {
      if (this.campaign.dummy) {
        this.campaign.turnover_budget = null
      }
    }
  },
}
</script>
